import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import TeamIcon from '../components/team/teamIcon'
import * as S from '../styles/pages/teamStyle'
import * as Shape1 from '../assets/images/static/shape5.svg'

export default function Team({ data }) {
  const imagesMap = new Map()
  data.teamImages.edges.forEach(elem => {
    imagesMap.set(elem.node.name, elem.node.publicURL)
  })

  const sortedTeamArr = []
  data.team.edges.forEach(elem => {
    const item = elem.node.frontmatter
    sortedTeamArr[item.order - 1] = item
  })

  const slides = sortedTeamArr.map(teamMember => {
    return (
      <TeamIcon
        key={teamMember.id}
        title={teamMember.name}
        text={teamMember.fulltext}
        image={imagesMap.get(teamMember.id)}
        alt={teamMember.photoAlt}
      />
    )
  })

  return (
    <Layout>
      <S.TeamSection>
        <S.TeamShape1>
          <Shape1 />
        </S.TeamShape1>
        <S.MainHeader>Nasi pracownicy</S.MainHeader>
        <S.TeamContainer>{slides}</S.TeamContainer>
      </S.TeamSection>
    </Layout>
  )
}

export const query = graphql`
  query TeamQuery {
    team: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "markdowns/team/.*.md/" } }
    ) {
      edges {
        node {
          frontmatter {
            name
            id
            photoAlt
            text
            fulltext
            order
          }
        }
      }
    }
    teamImages: allFile(
      filter: { absolutePath: { regex: "assets/images/team/.*.jpg/" } }
    ) {
      edges {
        node {
          name
          absolutePath
          publicURL
        }
      }
    }
  }
`
