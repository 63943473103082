import styled from 'styled-components'
import * as G from '../../styles/globalStyle'

export const TeamSection = styled.section`
  position: relative;
`

export const TeamContainer = styled(G.Container)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;

  @media (min-width: ${props => props.theme.size.huge}) {
    max-width: 1200px;
  }
`

export const MainHeader = styled(G.MainHeader)`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
`

export const TeamShape1 = styled(G.Shape)`
  top: 60%;
  left: -113px;
  transform: rotate(107deg);

  svg {
    transform: scale(0.6);

    @media (min-width: ${props => props.theme.size.medium}) {
      transform: scale(1);
    }
  }
`
