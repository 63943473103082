import React from 'react'
import * as S from '../../styles/components/team/teamStyle'

export default function TeamIcon({ image, title, text, alt }) {
  return (
    <S.TeamIconContainer>
      <S.SlideImage src={image} alt={alt} />
      <S.SlideTitle>{title}</S.SlideTitle>
      <S.TeamIconText>{text}</S.TeamIconText>
    </S.TeamIconContainer>
  )
}
